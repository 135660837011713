<template>
    <b-table v-if="tableData" hover :items="tableData" responsive :fields="fields" class="mb-0">
      <!-- id -->
      <template #cell(id)="data">
        <div class="font-weight-bolder">
          {{ data.item.id }}
        </div>
      </template>



      <!-- name -->
      <template #cell(description)="data">
        <router-link :to="'/triage-rules/'+data.item.id">{{ data.item.description }}</router-link>
      </template>


      
      <!-- advice -->
      <template #cell(active)="data">
        <div class="batch_container">
          <span v-if="data.item.active">
            <b-badge
              
              variant="primary"
              
              title="Active version"
            >
              ACTIVE
            </b-badge>
            </span>
            </div>
      </template>

      
      <!-- actions -->
      <template #cell(actions)="data">

        <span title="Create a copy of this version">
          <feather-icon
            icon="CopyIcon"
            class="cursor-pointer"
            size="16"
            alt="sdf"
            @click="createNewVersion(data.item)"
          />
        </span>
        
        <span title="Activate this version">
          <feather-icon v-if="!data.item.active"
            icon="PlayIcon"
            class="cursor-pointer"
            size="16"
            style="margin-left:1em;"
            @click="setActive(data.item)"
          />
        </span>
     
        <!-- <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown> -->
      </template>
    </b-table>
</template>

<script>
import {
  BCard,
  BBadge,
  BTable,
  BAvatar,
  BTooltip,
  BImg,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  emits:['editAdvice', 'deleteAdvice'],
  components: {
    BCard,
    BBadge,
    BTable,
    BAvatar,
    BTooltip,
    BImg,
    BDropdown,
    BDropdownItem,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fields: [
        { key: "id", label: "ID" },
        
        { key: "description", label: "DESCRIPTION" },
        { key: "active", label: "ACTIVE" },
        { key: "actions", label: "ACTIONS" },
      ],
    };
  },
  methods: {
    createNewVersion(advice) {
      this.$emit('createNewVersion',advice)
    },
    setActive(advice) {
      this.$emit('setActive',advice)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
