<template>
    <section id="versions">
      <!-- Advices Table Card -->
      <b-card no-body>
        <!-- Table Top -->
        <!-- <b-row>
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end  mt-2 mr-2">
              <b-button variant="primary" @click="active = !active; data.selectedAdvice={}">
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span class="text-nowrap">Add advice</span>
              </b-button>
            </div>
          </b-col>
        </b-row>       -->
        <versions-table
          @createNewVersion="createNewVersion"
          @setActive="setActive"
          :table-data="data.versions"
          class="mt-2 mt-md-2" />
      </b-card>
      <!--/ Advices Table Card -->
  
      <!-- SIDEBAR -->
      <b-sidebar
        width="40%"
        right
        shadow
        parent="body"
        default-index="1"
        spacer
        v-model="active"
        title="Advice settings"
        class="sidebar"
      >
        <div class="px-3 py-2">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Name"
                  label-for="inputName"
                  label-cols-md="12"
                >
                  <b-form-input
                    id="inputName"
                    placeholder="Advice name"
                    v-model="data.selectedVersion.name"
                    />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Advice"
                  label-for="textarea-description"
                  label-cols-md="12"
                >
                  <b-form-textarea
                    id="textarea-description"
                    placeholder="Advice text"
                    rows="3"
                    v-model="data.selectedVersion.description"
                  />
                </b-form-group>
              </b-col>
  
              <!-- save -->
              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="saveVersion"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-sidebar>
      <!-- /SIDEBAR -->
    </section>
  </template>
  
  
  
  <script>
  import {
    BCard,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BSidebar,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import VersionsTable from "./TriageRulesVersionsTable.vue";
  
  import Ripple from "vue-ripple-directive";
  import Cleave from "vue-cleave-component";
  
  export default {
    data() {
      return {
        active: false,
        data: {
          selectedVersion:{},
          versions: [],
        },
      };
    },
    components: {
      BRow,
      BCol,
      BCard,
      BCardText,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BButton,
      vSelect,
      Cleave,
      VersionsTable,
      BSidebar,
    },
    directives: {
      Ripple,
    },
    methods: {
      async saveVersion() {
        var url = '/triageruleversions';
        var method = 'post'
        if (this.data.selectedVersion.id) {
            url = '/triagevruleversions/'+this.data.selectedVersion.id
            method = 'put'
        }
          
  
        const res = await this.callApi(method, url, this.data.selectedAdvice);
  
        this.active=false;
        this.loadVersions()
  
      },
      async createNewVersion(version) {
        if (!confirm("Are you sure you want to create a new version based on '"+version.description+"'?")) return;


        const res = await this.callApi('post', '/triageruleversions/createCopy/'+version.id, {name:prompt("Enter name")});
        this.loadVersions()
        
      },
      async setActive(version) {
        if (!confirm("Are you sure you want to activate this version?")) return;
        const res = await this.callApi('post', '/triageruleversions/setActive/'+version.id);
        this.loadVersions()
      },
      async loadVersions() {
        const res = await this.callApi('get', '/triageruleversions');
          this.data.versions = res.data.result;
      },
      onContext(ctx) {
        
        // The date formatted in the locale, or the `label-no - date - selected` string
        this.formatted = ctx.selectedFormatted;
        // The following will be an empty string until a valid date is entered
        this.dobSelected = ctx.selectedYMD;
      },
    },
    mounted() {
      this.loadVersions();
    },
    
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  
  .col-form-label {
    min-height: 2.45rem;
  }
  </style>